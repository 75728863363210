import { Flex } from '@chakra-ui/react'

const AppLayout = ({ children }) => {
  return (
    <Flex minHeight="100vh" width="100vw" maxWidth="100vw" direction="column" background='#111'>
      {children}
    </Flex>
  )
}

export default AppLayout
