import { ChakraProvider } from "@chakra-ui/react"
import { AppProps } from 'next/app'

import GlobalProvider from "../context/GlobalContext/GlobalProvider"
import AppNavigation from "../components/AppNavigation"
import AppLayout from "../layouts/AppLayout"

import '../styles/global.css'
import { Web3Provider } from "../context/Web3Context"

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <GlobalProvider>
      <ChakraProvider>
        <Web3Provider>
          <AppLayout>
            <AppNavigation />
            <Component {...pageProps} />
          </AppLayout>
        </Web3Provider>
      </ChakraProvider>
    </GlobalProvider>
  )
}

export default App
