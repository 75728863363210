import { captureException } from '@sentry/nextjs'

import { FirebaseApp, initializeApp } from 'firebase/app'
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'

import firebaseConfig from "../../firebaseConfig"
import globalContext from './globalContext'

const GlobalProvider = ({ children }) => {
  let firebaseApp: FirebaseApp
  let firebaseAnalytics: Analytics

  if (typeof navigator !== 'undefined') {
    firebaseApp = initializeApp(firebaseConfig)
    firebaseAnalytics = getAnalytics()
    getPerformance()
  }

  const analytics = {
    logEvent: (eventName: string, eventParams: Record<string, string | number>) => {
      try {
        logEvent(firebaseAnalytics, eventName, eventParams)
      } catch (error) {
        captureException(error)
      }
    }
  }

  return (
    <globalContext.Provider value={{ analytics }}>
      {children}
    </globalContext.Provider>
  )
}

export default GlobalProvider
