import { ethers } from 'ethers'
import { useReducer } from 'react'
import context from './web3Context'
import { Web3Action, Web3State } from './web3types'

const setSigner = (state: Web3State, signer: ethers.providers.JsonRpcSigner) => {
  return {...state, signer}
}

const reducer = (state: Web3State, action: Web3Action) => {
  return {
    setSigner
  }[action.type](state, action.value)
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { signer: null })
  return <context.Provider value={{ state, dispatch }}>{children}</context.Provider>
}

export default Provider
