import { ethers } from "ethers";
import { createContext, Dispatch } from "react";

let dispatch: Dispatch<any>
let state: {
  signer: ethers.providers.JsonRpcSigner
}

const context = createContext({ state, dispatch })

export default context
